<template>
  <v-container>
    <h1> <textloader-component textKey="SongbookRubrik"/> </h1>
    <paragraph-component> <textloader-component textKey="SongbookBrödtext"/> </paragraph-component>
    <v-autocomplete
      v-model="input"
      label="Sök sång"
      :items="songs"
      item-text="namn"
      :filter="filterObject"
      @change="updateView"
    ></v-autocomplete>
    <v-container>
      <v-row justify="center">
        <v-col cols="8">
          <v-container class="max-width">
            <v-pagination
              v-model="page"
              class="my-4"
              :length="totalPages"
            ></v-pagination>
            <v-row justify="center">
              <v-col cols="12" md="6" v-for="song in paginatedSongs" :key="song.id">
                <v-card>
                  <v-card-title>{{ song.namn }}</v-card-title>
                  <v-card-subtitle>{{ song.melodi }}</v-card-subtitle>
                  <v-card-text v-html="song.text"></v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import ParagraphComponent from '@/components/ParagraphComponent'
import TextloaderComponent from '@/components/TextloaderComponent'
import songs from '@/assets/songs.json'
// Docs: https://vuetifyjs.com/en/components/autocompletes/#density
export default {
  components: {
    ParagraphComponent,
    TextloaderComponent
  },
  data () {
    return {
      input: '',
      songs: songs,
      titles: [],
      page: 1,
      perPage: 2
    }
  },
  computed: {
    paginatedSongs () {
      const start = (this.page - 1) * this.perPage
      const end = this.page * this.perPage
      return this.songs.slice(start, end)
    },
    totalPages () {
      return Math.ceil(this.songs.length / this.perPage)
    }
  },
  mounted () {
    this.songs.forEach(song => {
      this.titles.push(song.namn)
    })
  },
  methods: {
    filterObject (item, queryText, itemText) {
      return (
        item.namn.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) >
          -1 ||
        item.text.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
      )
    },
    updateView () {
      this.page = Math.ceil((this.songs.findIndex(song => song.namn === this.input) + 1) / this.perPage)
    }
  }
}
</script>
